@font-face {
	font-family: "font-landing";
	font-display: block;
	src: url("/landing.icons.font-landing.36dd170ebe1140b8f613.eot?#iefix") format("embedded-opentype"),
url("/landing.icons.font-landing.36dd170ebe1140b8f613.woff") format("woff"),
url("/landing.icons.font-landing.36dd170ebe1140b8f613.woff2") format("woff2"),
url("/landing.icons.font-landing.36dd170ebe1140b8f613.ttf") format("truetype");
}

.pp-landing {
	line-height: 1;
}

.pp-landing:before {
	font-family: font-landing !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.pp-a-file-text-o:before {
	content: "\f101";
}
.pp-a-folder-open-o:before {
	content: "\f102";
}
.pp-angle-left:before {
	content: "\f103";
}
.pp-angle-right:before {
	content: "\f104";
}
.pp-bars:before {
	content: "\f105";
}
.pp-briefcase:before {
	content: "\f106";
}
.pp-chevron-left:before {
	content: "\f107";
}
.pp-close-thin:before {
	content: "\f108";
}
.pp-close:before {
	content: "\f109";
}
.pp-comments:before {
	content: "\f10a";
}
.pp-company-lots:before {
	content: "\f10b";
}
.pp-edit:before {
	content: "\f10c";
}
.pp-ellipsis-h:before {
	content: "\f10d";
}
.pp-envelope-o:before {
	content: "\f10e";
}
.pp-file-text:before {
	content: "\f10f";
}
.pp-filter_area:before {
	content: "\f110";
}
.pp-filter_brand:before {
	content: "\f111";
}
.pp-filter_calendar:before {
	content: "\f112";
}
.pp-filter_car-type:before {
	content: "\f113";
}
.pp-filter_clothes-size:before {
	content: "\f114";
}
.pp-filter_distance:before {
	content: "\f115";
}
.pp-filter_engine-volume:before {
	content: "\f116";
}
.pp-filter_floor:before {
	content: "\f117";
}
.pp-filter_floors:before {
	content: "\f118";
}
.pp-filter_fuel:before {
	content: "\f119";
}
.pp-filter_house-type:before {
	content: "\f11a";
}
.pp-filter_land:before {
	content: "\f11b";
}
.pp-filter_lenght:before {
	content: "\f11c";
}
.pp-filter_mot:before {
	content: "\f11d";
}
.pp-filter_rooms:before {
	content: "\f11e";
}
.pp-filter_transmission:before {
	content: "\f11f";
}
.pp-filter_waist:before {
	content: "\f120";
}
.pp-filter:before {
	content: "\f121";
}
.pp-gallery-panorama:before {
	content: "\f122";
}
.pp-hdd-o:before {
	content: "\f123";
}
.pp-heart-o:before {
	content: "\f124";
}
.pp-heart:before {
	content: "\f125";
}
.pp-key:before {
	content: "\f126";
}
.pp-language:before {
	content: "\f127";
}
.pp-my-lots:before {
	content: "\f128";
}
.pp-package-delivery:before {
	content: "\f129";
}
.pp-paper-plane:before {
	content: "\f12a";
}
.pp-pencil:before {
	content: "\f12b";
}
.pp-picture-o:before {
	content: "\f12c";
}
.pp-plus-circle:before {
	content: "\f12d";
}
.pp-power-off:before {
	content: "\f12e";
}
.pp-pp:before {
	content: "\f12f";
}
.pp-question-circle-o:before {
	content: "\f130";
}
.pp-search:before {
	content: "\f131";
}
.pp-sign-out:before {
	content: "\f132";
}
.pp-social-facebook:before {
	content: "\f133";
}
.pp-social-instagram:before {
	content: "\f134";
}
.pp-social-tiktok-auto:before {
	content: "\f135";
}
.pp-social-tiktok-flats:before {
	content: "\f136";
}
.pp-theme:before {
	content: "\f137";
}
.pp-user:before {
	content: "\f138";
}
.pp-video-camera:before {
	content: "\f139";
}
.pp-visited:before {
	content: "\f13a";
}
.pp-zcat_adult:before {
	content: "\f13b";
}
.pp-zcat_agriculture:before {
	content: "\f13c";
}
.pp-zcat_charity:before {
	content: "\f13d";
}
.pp-zcat_clothes:before {
	content: "\f13e";
}
.pp-zcat_construction:before {
	content: "\f13f";
}
.pp-zcat_electronics:before {
	content: "\f140";
}
.pp-zcat_food:before {
	content: "\f141";
}
.pp-zcat_gardening:before {
	content: "\f142";
}
.pp-zcat_health:before {
	content: "\f143";
}
.pp-zcat_hobby:before {
	content: "\f144";
}
.pp-zcat_home_stuff:before {
	content: "\f145";
}
.pp-zcat_kids:before {
	content: "\f146";
}
.pp-zcat_pets:before {
	content: "\f147";
}
.pp-zcat_production:before {
	content: "\f148";
}
.pp-zcat_real_estate:before {
	content: "\f149";
}
.pp-zcat_services:before {
	content: "\f14a";
}
.pp-zcat_transport:before {
	content: "\f14b";
}
