@font-face {
	font-family: "font-category";
	font-display: block;
	src: url("/category.icons.font-category.d26472f9f400cddc6e11.eot?#iefix") format("embedded-opentype"),
url("/category.icons.font-category.d26472f9f400cddc6e11.woff") format("woff"),
url("/category.icons.font-category.d26472f9f400cddc6e11.woff2") format("woff2"),
url("/category.icons.font-category.d26472f9f400cddc6e11.ttf") format("truetype");
}

.pp-category {
	line-height: 1;
}

.pp-category:before {
	font-family: font-category !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.pp-zcat_adult_clothes:before {
	content: "\f101";
}
.pp-zcat_adult_games:before {
	content: "\f102";
}
.pp-zcat_adult_masturbator:before {
	content: "\f103";
}
.pp-zcat_adult_offers:before {
	content: "\f104";
}
.pp-zcat_adult_pharmacy:before {
	content: "\f105";
}
.pp-zcat_adult_strapon:before {
	content: "\f106";
}
.pp-zcat_adult_toys:before {
	content: "\f107";
}
.pp-zcat_adult_vibrator:before {
	content: "\f108";
}
.pp-zcat_agriculture_aviculture:before {
	content: "\f109";
}
.pp-zcat_agriculture_fertilizer:before {
	content: "\f10a";
}
.pp-zcat_agriculture_fish-industry:before {
	content: "\f10b";
}
.pp-zcat_agriculture_husbandry:before {
	content: "\f10c";
}
.pp-zcat_agriculture_machinery:before {
	content: "\f10d";
}
.pp-zcat_agriculture_seeds:before {
	content: "\f10e";
}
.pp-zcat_agriculture_works:before {
	content: "\f10f";
}
.pp-zcat_christmas_offers:before {
	content: "\f110";
}
.pp-zcat_christmas:before {
	content: "\f111";
}
.pp-zcat_clothes_accessories:before {
	content: "\f112";
}
.pp-zcat_clothes_kids:before {
	content: "\f113";
}
.pp-zcat_clothes_men:before {
	content: "\f114";
}
.pp-zcat_clothes_overalls:before {
	content: "\f115";
}
.pp-zcat_clothes_seamstress_service:before {
	content: "\f116";
}
.pp-zcat_clothes_shoes:before {
	content: "\f117";
}
.pp-zcat_clothes_women:before {
	content: "\f118";
}
.pp-zcat_construction_delivery:before {
	content: "\f119";
}
.pp-zcat_construction_design:before {
	content: "\f11a";
}
.pp-zcat_construction_heating:before {
	content: "\f11b";
}
.pp-zcat_construction_materials:before {
	content: "\f11c";
}
.pp-zcat_construction_plumbing:before {
	content: "\f11d";
}
.pp-zcat_construction_tool-rent:before {
	content: "\f11e";
}
.pp-zcat_construction_tools:before {
	content: "\f11f";
}
.pp-zcat_construction_works:before {
	content: "\f120";
}
.pp-zcat_electronics_appliances:before {
	content: "\f121";
}
.pp-zcat_electronics_audio-video:before {
	content: "\f122";
}
.pp-zcat_electronics_batteries:before {
	content: "\f123";
}
.pp-zcat_electronics_cameras:before {
	content: "\f124";
}
.pp-zcat_electronics_computers:before {
	content: "\f125";
}
.pp-zcat_electronics_gps:before {
	content: "\f126";
}
.pp-zcat_electronics_lamps:before {
	content: "\f127";
}
.pp-zcat_electronics_mobile-phones:before {
	content: "\f128";
}
.pp-zcat_electronics_security-camera:before {
	content: "\f129";
}
.pp-zcat_electronics_tv:before {
	content: "\f12a";
}
.pp-zcat_food_alcohol:before {
	content: "\f12b";
}
.pp-zcat_food_bread:before {
	content: "\f12c";
}
.pp-zcat_food_drinks:before {
	content: "\f12d";
}
.pp-zcat_food_frozen:before {
	content: "\f12e";
}
.pp-zcat_food_fruits:before {
	content: "\f12f";
}
.pp-zcat_food_grain:before {
	content: "\f130";
}
.pp-zcat_food_honey:before {
	content: "\f131";
}
.pp-zcat_food_meat:before {
	content: "\f132";
}
.pp-zcat_food_milk:before {
	content: "\f133";
}
.pp-zcat_food_rural-goods:before {
	content: "\f134";
}
.pp-zcat_food_spices:before {
	content: "\f135";
}
.pp-zcat_food_veggie:before {
	content: "\f136";
}
.pp-zcat_gardening_accessories:before {
	content: "\f137";
}
.pp-zcat_gardening_clothing:before {
	content: "\f138";
}
.pp-zcat_gardening_engines:before {
	content: "\f139";
}
.pp-zcat_gardening_for-children:before {
	content: "\f13a";
}
.pp-zcat_gardening_furniture:before {
	content: "\f13b";
}
.pp-zcat_gardening_generators:before {
	content: "\f13c";
}
.pp-zcat_gardening_grass:before {
	content: "\f13d";
}
.pp-zcat_gardening_greenhouse:before {
	content: "\f13e";
}
.pp-zcat_gardening_mechanical-brooms:before {
	content: "\f13f";
}
.pp-zcat_gardening_saw:before {
	content: "\f140";
}
.pp-zcat_gardening_snow-plow:before {
	content: "\f141";
}
.pp-zcat_gardening_watering:before {
	content: "\f142";
}
.pp-zcat_health_accessories:before {
	content: "\f143";
}
.pp-zcat_health_beauty:before {
	content: "\f144";
}
.pp-zcat_health_dancing:before {
	content: "\f145";
}
.pp-zcat_health_fitness:before {
	content: "\f146";
}
.pp-zcat_health_hairdresser:before {
	content: "\f147";
}
.pp-zcat_health_health:before {
	content: "\f148";
}
.pp-zcat_health_healthcare:before {
	content: "\f149";
}
.pp-zcat_health_manicure:before {
	content: "\f14a";
}
.pp-zcat_health_massage:before {
	content: "\f14b";
}
.pp-zcat_health_sauna:before {
	content: "\f14c";
}
.pp-zcat_health_stylist:before {
	content: "\f14d";
}
.pp-zcat_hobby_books:before {
	content: "\f14e";
}
.pp-zcat_hobby_collecting:before {
	content: "\f14f";
}
.pp-zcat_hobby_concerts:before {
	content: "\f150";
}
.pp-zcat_hobby_dating:before {
	content: "\f151";
}
.pp-zcat_hobby_hunting:before {
	content: "\f152";
}
.pp-zcat_hobby_music:before {
	content: "\f153";
}
.pp-zcat_hobby_pastime:before {
	content: "\f154";
}
.pp-zcat_hobby_sports:before {
	content: "\f155";
}
.pp-zcat_hobby_tourism:before {
	content: "\f156";
}
.pp-zcat_home_stuff_antique:before {
	content: "\f157";
}
.pp-zcat_home_stuff_beauty:before {
	content: "\f158";
}
.pp-zcat_home_stuff_detergents:before {
	content: "\f159";
}
.pp-zcat_home_stuff_feast-attributes:before {
	content: "\f15a";
}
.pp-zcat_home_stuff_furniture:before {
	content: "\f15b";
}
.pp-zcat_home_stuff_home-plant:before {
	content: "\f15c";
}
.pp-zcat_home_stuff_jewelry:before {
	content: "\f15d";
}
.pp-zcat_home_stuff_laf:before {
	content: "\f15e";
}
.pp-zcat_home_stuff_souvenir:before {
	content: "\f15f";
}
.pp-zcat_kids_bag:before {
	content: "\f160";
}
.pp-zcat_kids_food:before {
	content: "\f161";
}
.pp-zcat_kids_furniture:before {
	content: "\f162";
}
.pp-zcat_kids_hobby:before {
	content: "\f163";
}
.pp-zcat_kids_pushchair:before {
	content: "\f164";
}
.pp-zcat_kids_school:before {
	content: "\f165";
}
.pp-zcat_kids_seats:before {
	content: "\f166";
}
.pp-zcat_kids_swing:before {
	content: "\f167";
}
.pp-zcat_other:before {
	content: "\f168";
}
.pp-zcat_pets_bed:before {
	content: "\f169";
}
.pp-zcat_pets_birds:before {
	content: "\f16a";
}
.pp-zcat_pets_bowl:before {
	content: "\f16b";
}
.pp-zcat_pets_cage:before {
	content: "\f16c";
}
.pp-zcat_pets_cats:before {
	content: "\f16d";
}
.pp-zcat_pets_collar:before {
	content: "\f16e";
}
.pp-zcat_pets_dogs:before {
	content: "\f16f";
}
.pp-zcat_pets_exotic:before {
	content: "\f170";
}
.pp-zcat_pets_fish:before {
	content: "\f171";
}
.pp-zcat_pets_food:before {
	content: "\f172";
}
.pp-zcat_pets_hygiene:before {
	content: "\f173";
}
.pp-zcat_pets_other:before {
	content: "\f174";
}
.pp-zcat_pets_playground:before {
	content: "\f175";
}
.pp-zcat_pets_rodents:before {
	content: "\f176";
}
.pp-zcat_pets_terrarium:before {
	content: "\f177";
}
.pp-zcat_pets_toilet:before {
	content: "\f178";
}
.pp-zcat_pets_toys:before {
	content: "\f179";
}
.pp-zcat_pets_veterinary:before {
	content: "\f17a";
}
.pp-zcat_production_conveyor:before {
	content: "\f17b";
}
.pp-zcat_production_distribution:before {
	content: "\f17c";
}
.pp-zcat_production_firewood:before {
	content: "\f17d";
}
.pp-zcat_production_food:before {
	content: "\f17e";
}
.pp-zcat_production_household:before {
	content: "\f17f";
}
.pp-zcat_production_manufacturing:before {
	content: "\f180";
}
.pp-zcat_production_packaging:before {
	content: "\f181";
}
.pp-zcat_real_estate_apartments:before {
	content: "\f182";
}
.pp-zcat_real_estate_farmstead:before {
	content: "\f183";
}
.pp-zcat_real_estate_forest:before {
	content: "\f184";
}
.pp-zcat_real_estate_garage:before {
	content: "\f185";
}
.pp-zcat_real_estate_houses:before {
	content: "\f186";
}
.pp-zcat_real_estate_lands:before {
	content: "\f187";
}
.pp-zcat_real_estate_modular-homes:before {
	content: "\f188";
}
.pp-zcat_real_estate_offices:before {
	content: "\f189";
}
.pp-zcat_real_estate_premises:before {
	content: "\f18a";
}
.pp-zcat_real_estate_saunas:before {
	content: "\f18b";
}
.pp-zcat_services_beauty:before {
	content: "\f18c";
}
.pp-zcat_services_car-repair:before {
	content: "\f18d";
}
.pp-zcat_services_construction:before {
	content: "\f18e";
}
.pp-zcat_services_craftsman:before {
	content: "\f18f";
}
.pp-zcat_services_delivery:before {
	content: "\f190";
}
.pp-zcat_services_electronics-repair:before {
	content: "\f191";
}
.pp-zcat_services_events:before {
	content: "\f192";
}
.pp-zcat_services_finishing-works:before {
	content: "\f193";
}
.pp-zcat_services_household-chores:before {
	content: "\f194";
}
.pp-zcat_services_household:before {
	content: "\f195";
}
.pp-zcat_services_interior-design:before {
	content: "\f196";
}
.pp-zcat_services_internet-it:before {
	content: "\f197";
}
.pp-zcat_services_professional:before {
	content: "\f198";
}
.pp-zcat_services_teachers:before {
	content: "\f199";
}
.pp-zcat_services_vacancy:before {
	content: "\f19a";
}
.pp-zcat_services_volunteer:before {
	content: "\f19b";
}
.pp-zcat_transport_accessories:before {
	content: "\f19c";
}
.pp-zcat_transport_air:before {
	content: "\f19d";
}
.pp-zcat_transport_audio-navigation-systems:before {
	content: "\f19e";
}
.pp-zcat_transport_bicycle:before {
	content: "\f19f";
}
.pp-zcat_transport_bus:before {
	content: "\f1a0";
}
.pp-zcat_transport_cars:before {
	content: "\f1a1";
}
.pp-zcat_transport_disabled-person:before {
	content: "\f1a2";
}
.pp-zcat_transport_electric:before {
	content: "\f1a3";
}
.pp-zcat_transport_heavy:before {
	content: "\f1a4";
}
.pp-zcat_transport_motorcycle:before {
	content: "\f1a5";
}
.pp-zcat_transport_other:before {
	content: "\f1a6";
}
.pp-zcat_transport_rental:before {
	content: "\f1a7";
}
.pp-zcat_transport_rims:before {
	content: "\f1a8";
}
.pp-zcat_transport_spares:before {
	content: "\f1a9";
}
.pp-zcat_transport_tire:before {
	content: "\f1aa";
}
.pp-zcat_transport_tires:before {
	content: "\f1ab";
}
.pp-zcat_transport_trailer:before {
	content: "\f1ac";
}
.pp-zcat_transport_train:before {
	content: "\f1ad";
}
.pp-zcat_transport_tram:before {
	content: "\f1ae";
}
.pp-zcat_transport_transportation:before {
	content: "\f1af";
}
.pp-zcat_transport_trolleybus:before {
	content: "\f1b0";
}
.pp-zcat_transport_water:before {
	content: "\f1b1";
}
