@font-face {
	font-family: "font-ico";
	font-display: block;
	src: url("/ico.icons.font-ico.d4c21d9d051806ce2761.eot?#iefix") format("embedded-opentype"),
url("/ico.icons.font-ico.d4c21d9d051806ce2761.woff") format("woff"),
url("/ico.icons.font-ico.d4c21d9d051806ce2761.woff2") format("woff2"),
url("/ico.icons.font-ico.d4c21d9d051806ce2761.ttf") format("truetype");
}

.pp-ico {
	line-height: 1;
}

.pp-ico:before {
	font-family: font-ico !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.pp-a-angle-down:before {
	content: "\f101";
}
.pp-a-avatar:before {
	content: "\f102";
}
.pp-a-check-square:before {
	content: "\f103";
}
.pp-a-folder-o:before {
	content: "\f104";
}
.pp-a-minus-square-o:before {
	content: "\f105";
}
.pp-a-play:before {
	content: "\f106";
}
.pp-a-plus-square-o:before {
	content: "\f107";
}
.pp-a-square-o:before {
	content: "\f108";
}
.pp-action_type_all:before {
	content: "\f109";
}
.pp-action_type_buy:before {
	content: "\f10a";
}
.pp-action_type_change:before {
	content: "\f10b";
}
.pp-action_type_find_job:before {
	content: "\f10c";
}
.pp-action_type_found:before {
	content: "\f10d";
}
.pp-action_type_gift:before {
	content: "\f10e";
}
.pp-action_type_hire:before {
	content: "\f10f";
}
.pp-action_type_lost:before {
	content: "\f110";
}
.pp-action_type_offer_job:before {
	content: "\f111";
}
.pp-action_type_rent:before {
	content: "\f112";
}
.pp-action_type_sell:before {
	content: "\f113";
}
.pp-arrow-circle-o-up:before {
	content: "\f114";
}
.pp-arrow-left:before {
	content: "\f115";
}
.pp-arrow-right:before {
	content: "\f116";
}
.pp-arrow-up:before {
	content: "\f117";
}
.pp-bar-chart:before {
	content: "\f118";
}
.pp-broken-link:before {
	content: "\f119";
}
.pp-calendar-check-o:before {
	content: "\f11a";
}
.pp-car_type_cabriolet:before {
	content: "\f11b";
}
.pp-car_type_coupe:before {
	content: "\f11c";
}
.pp-car_type_hatchback:before {
	content: "\f11d";
}
.pp-car_type_minibus:before {
	content: "\f11e";
}
.pp-car_type_minivan:before {
	content: "\f11f";
}
.pp-car_type_offroad:before {
	content: "\f120";
}
.pp-car_type_other:before {
	content: "\f121";
}
.pp-car_type_sedan:before {
	content: "\f122";
}
.pp-car_type_universal:before {
	content: "\f123";
}
.pp-check-circle:before {
	content: "\f124";
}
.pp-check-thin:before {
	content: "\f125";
}
.pp-chevron-circle-down:before {
	content: "\f126";
}
.pp-chevron-right:before {
	content: "\f127";
}
.pp-clock:before {
	content: "\f128";
}
.pp-cog:before {
	content: "\f129";
}
.pp-comments-o:before {
	content: "\f12a";
}
.pp-copy:before {
	content: "\f12b";
}
.pp-credit-card:before {
	content: "\f12c";
}
.pp-delivery:before {
	content: "\f12d";
}
.pp-empty-chat:before {
	content: "\f12e";
}
.pp-error404:before {
	content: "\f12f";
}
.pp-error500:before {
	content: "\f130";
}
.pp-eur:before {
	content: "\f131";
}
.pp-exclamation-circle:before {
	content: "\f132";
}
.pp-exclamation-triangle:before {
	content: "\f133";
}
.pp-eye-slash:before {
	content: "\f134";
}
.pp-eye:before {
	content: "\f135";
}
.pp-feedback-bad:before {
	content: "\f136";
}
.pp-feedback-good:before {
	content: "\f137";
}
.pp-feedback-hate:before {
	content: "\f138";
}
.pp-feedback-loved:before {
	content: "\f139";
}
.pp-feedback-neutral:before {
	content: "\f13a";
}
.pp-flag:before {
	content: "\f13b";
}
.pp-floppy-o:before {
	content: "\f13c";
}
.pp-folder-open:before {
	content: "\f13d";
}
.pp-gavel:before {
	content: "\f13e";
}
.pp-globe:before {
	content: "\f13f";
}
.pp-home:before {
	content: "\f140";
}
.pp-info-circle:before {
	content: "\f141";
}
.pp-info:before {
	content: "\f142";
}
.pp-landing_users:before {
	content: "\f143";
}
.pp-linkedin:before {
	content: "\f144";
}
.pp-list:before {
	content: "\f145";
}
.pp-long-arrow-left:before {
	content: "\f146";
}
.pp-magic:before {
	content: "\f147";
}
.pp-map-marker:before {
	content: "\f148";
}
.pp-minus:before {
	content: "\f149";
}
.pp-move-img:before {
	content: "\f14a";
}
.pp-paper-plane-o:before {
	content: "\f14b";
}
.pp-password:before {
	content: "\f14c";
}
.pp-payment:before {
	content: "\f14d";
}
.pp-pencil-square-o:before {
	content: "\f14e";
}
.pp-percent:before {
	content: "\f14f";
}
.pp-phone-classic:before {
	content: "\f150";
}
.pp-phone-folding:before {
	content: "\f151";
}
.pp-phone-sensory:before {
	content: "\f152";
}
.pp-phone-slider:before {
	content: "\f153";
}
.pp-phone:before {
	content: "\f154";
}
.pp-picture:before {
	content: "\f155";
}
.pp-plan:before {
	content: "\f156";
}
.pp-plus:before {
	content: "\f157";
}
.pp-print:before {
	content: "\f158";
}
.pp-qr-code:before {
	content: "\f159";
}
.pp-recommended:before {
	content: "\f15a";
}
.pp-refresh:before {
	content: "\f15b";
}
.pp-repeat:before {
	content: "\f15c";
}
.pp-save:before {
	content: "\f15d";
}
.pp-seller:before {
	content: "\f15e";
}
.pp-share-square-o:before {
	content: "\f15f";
}
.pp-smileys:before {
	content: "\f160";
}
.pp-sort-amount-asc:before {
	content: "\f161";
}
.pp-star-o:before {
	content: "\f162";
}
.pp-star:before {
	content: "\f163";
}
.pp-streetad:before {
	content: "\f164";
}
.pp-subscribe:before {
	content: "\f165";
}
.pp-subscription:before {
	content: "\f166";
}
.pp-team:before {
	content: "\f167";
}
.pp-th-large:before {
	content: "\f168";
}
.pp-th:before {
	content: "\f169";
}
.pp-thumbs-o-up:before {
	content: "\f16a";
}
.pp-times-circle:before {
	content: "\f16b";
}
.pp-trash-o:before {
	content: "\f16c";
}
.pp-trash:before {
	content: "\f16d";
}
.pp-wallet:before {
	content: "\f16e";
}
.pp-xml-json:before {
	content: "\f16f";
}
.pp-zcat_transport_xs_car:before {
	content: "\f170";
}
.pp-zcat_transport_xs_heavy:before {
	content: "\f171";
}
.pp-zcat_transport_xs_motorcycle:before {
	content: "\f172";
}
.pp-zcat_transport_xs_spares:before {
	content: "\f173";
}
.pp-zcat_transport_xs_tire:before {
	content: "\f174";
}
